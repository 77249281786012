<template>
  <v-layout
    class="main_desktop_cont"
    align-center
    justify-center
    column
    fill-height
    text-xs-center
  >
    <div class="login">
      <div class="login__inner block__content">
        <v-form v-model="valid" ref="form" @submit.prevent="login">
          <div class="login__logo">
            <img :src="require('../../assets/logo_with_text.svg')" />
          </div>
          <div class="login__logo-text">
            {{ changePassword ? "Сменить пароль" : "Войти в аккаунт" }}
          </div>

          <input-company
            v-model="code"
            :modelError.sync="modelError"
            :rules="[rules.required]"
            v-if="!changePassword"
            @focus="reset"
          />

          <v-text-field
            v-model="userName"
            v-if="!changePassword"
            ref="login"
            :rules="[rules.required, rules.cyrillicCheck, rules.spaceCheck]"
            label="Логин"
            placeholder=" "
            persistent-placeholder
            outlined
            required
            :error-messages="userNameError"
            @focus="reset"
            tabindex="3"
          >
          </v-text-field>
          <v-text-field
            v-model="userPassword"
            v-if="!changePassword"
            :rules="[rules.required, rules.spaceCheck]"
            @click:append="show1 = !show1"
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Пароль"
            placeholder=" "
            persistent-placeholder
            outlined
            required
            :error-messages="userPasswordError"
            @keydown="checkCapsLock($event, 'userPasswordError')"
            @focus="reset"
            tabindex="4"
          >
          </v-text-field>
          <v-text-field
            v-model="userPasswordNew"
            v-if="changePassword"
            :rules="[rules.required, rules.spaceCheck]"
            @click:append="show2 = !show2"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Новый пароль"
            placeholder=" "
            persistent-placeholder
            outlined
            required
            :error-messages="userPasswordNewError"
            @keydown="checkCapsLock($event, 'userPasswordNewError')"
            tabindex="5"
          >
          </v-text-field>
          <v-text-field
            v-model="userPasswordConfirmNew"
            v-if="changePassword"
            :rules="[rules.required, rules.spaceCheck, rules.equalToNew]"
            @click:append="show3 = !show3"
            :type="show3 ? 'text' : 'password'"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Введите пароль еще раз"
            placeholder=" "
            persistent-placeholder
            outlined
            required
            tabindex="6"
          >
          </v-text-field>

          <div
            v-if="false"
            class="login_forgot-password text-left primary--text"
          >
            Проблемы со входом?
          </div>

          <v-alert
            border="top"
            color="red lighten-2"
            dark
            v-if="error"
            style="white-space: pre-line"
          >
            {{ error }}
          </v-alert>

          <div class="login__info text-left">
            {{
              changePassword
                ? "Требуется сменить пароль, поскольку это ваш первый вход в систему или пароль был сброшен администратором"
                : "Используйте аккаунт Медэксперт, чтобы войти в систему."
            }}
          </div>

          <div class="text-right">
            <v-btn
              class="btn-normale"
              type="submit"
              color="primary"
              large
              dark
              :loading="loading"
              tabindex="7"
            >
              {{ changePassword ? "Сохранить и войти" : "Войти" }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </v-layout>
</template>

<script>
import AuthService from "@/services/AuthService";
import Cookies from "js-cookie";

import inputCompany from "./components/inputCompany.vue";

export default {
  name: "view-Login",
  components: { inputCompany },
  data() {
    const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
    return {
      loading: false,
      valid: true,
      userName: user?.UserName,
      userPassword: "",
      userPasswordNew: null,
      userPasswordConfirmNew: null,
      changePassword: false,
      code: null,
      show1: false,
      show2: false,
      show3: false,
      message: null,
      error: null,
      checkbox: true,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        equalToNew: (value) =>
          value === this.userPasswordNew || "Пароли не совпадают",
        cyrillicCheck: (val) => {
          const regexp = /[а-яё]/i;
          return !regexp.test(val) || "Запрещен ввод кириллицы!";
        },
        spaceCheck: (val) => {
          const regexp = /[\s]/i;
          return !regexp.test(val) || "Запрещен ввод пробела!";
        },
      },
      userPasswordError: null,
      userPasswordNewError: null,
      userNameError: null,
      modelError: null,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("OwnerCode")) {
      this.code = urlParams.get("OwnerCode");
      this.userName = urlParams.get("UserName");
      this.userPassword = urlParams.get("Password");
    }
  },
  methods: {
    reset() {
      this.userPasswordError = null;
      this.userPasswordNewError = null;
      this.userNameError = null;
      this.modelError = null;
    },
    async login() {
      this.reset();

      await new Promise((resolve) => {
        setTimeout(resolve, 1);
      });

      this.$refs.form.validate();
      if (!this.valid) return;

      this.error = null;
      this.message = null;
      this.loading = true;

      const pwd = this.userPassword;

      try {
        const { data } = await AuthService.login(
          this.userName,
          pwd,
          this.code,
          this.userPasswordNew
        );
        if (data?.User?.ChangePasswordOnFirstLogon === true) {
          this.loading = false;
          this.message = "Введите новый пароль";
          this.changePassword = true;
          this.$nextTick(() => {
            this.$refs.form.reset();
          });
          return;
        }

        document.location.href = "/";
      } catch (error) {
        this.loading = false;
        if (error.response.data.InternalErrorCode === 102) {
          this.userNameError = error.response.data.Message;
        } else if (error.response.data.InternalErrorCode === 104) {
          this.userPasswordError = error.response.data.Message;
        } else if (error.response.data.OwnerCode) {
          this.modelError = "Неверный код организации";
        } else {
          if (error.response.status === 422) {
            if (error.response.data.InternalErrorCode === 109) {
              this.error = `Уважаемый пользователь !\n
                            Вы использовали максимальное количество подключений для одного пользователя\n
                            Для входа в систему завершите работу на одном из используемых устройств`;
            } else if (error.response.data.InternalErrorCode === 108) {
              this.error = `Уважаемый пользователь !\n
                            Использовано максимальное количество подключений к системе, предусмотренных\n
                            вашей лицензией (${error.response.data.MaxUsersCount} пользователя(ей)). Попробуйте зайти через несколько минут.`;
            }
          } else this.error = error.response.data.Message;
        }
      }
      this.loading = false;
    },
    checkCapsLock(event, textError) {
      if (event?.getModifierState && event?.getModifierState("CapsLock")) {
        this[textError] = "Включен Caps Lock";
      } else {
        this[textError] = null;
      }
    },
  },
};
</script>
<style lang="scss">
.theme--light.v-icon {
  color: rgb(33, 33, 33);
}
</style>
<style>
.login__inner .v-text-field__slot label.v-label,
.login__inner .v-select__slot label.v-label {
  color: rgb(33, 33, 33, 0.7) !important;
  font-size: 14px;
  font-weight: 700;
}
</style>

<style lang="sass" scoped>
.login
  .v-input:not(:last-child)
    margin-bottom: 8px
  &__inner
    padding: 40px 60px
    width: 500px
    text-align: center
.login__logo-text
  margin: 40px 0
  font-size: 20px
  line-height: 158%
  letter-spacing: -0.01em
  color: rgb(33, 33, 33)

.login_forgot-password
  padding-left: 10px
  padding-top:10px
  font-weight: 500
  font-size: 14px
  line-height: 158%
  letter-spacing: -0.01em

.login__info
  padding: 15px 0 30px 0
  font-size: 14px
</style>
